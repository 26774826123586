.form-item {
    position: relative; /* Add this line */
}

.form-input {
    color: #111;
    background-color: #F9F9F9;
    width: 100%;
    padding: 28px 16px 10px;
    border-radius: 12px;
}

.form-label {
    color: #333 !important;
    font-size: 14px !important;
    position: relative;
    top: -42px !important;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
    position: relative;
    color: #313131 !important;
    top: -55px !important;
    font-size: 11px !important;
}

.form-input-textarea {
    color: #111;
    border-radius: 12px;
    background-color: #F9F9F9;
    border: none;
    padding: 28px 16px 10px;
    width: 100%;
}

.form-label-textarea {
    color: #333 !important;
    font-size: 14px !important;
    position: absolute !important;
    pointer-events: none;
    opacity: 0.4;
    top: 8px !important;
    left: 12px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.form-input-textarea:focus+.form-label-textarea,
.form-input-textarea:not(:placeholder-shown) + .form-label-textarea {
    color: #313131 !important;
    top: 6px !important; /* Adjust this value as needed */
    font-size: 11px !important;
    opacity: 1;
}

.ant-notification-notice-dark {
    background-color: #333 !important; /* Dark background */
    color: #fff !important; /* Light text color */
}

.ant-notification-notice-message {
    background-color: #333 !important; /* Dark background */
    color: #fff !important; /* Light text color */
}

@media (max-width: 767px) {
    .form-input {
        width: 320px !important;
        height: 50px !important;
    }
}