/* Dark mode styles */
body {
    background-color: #111;
    color: #ffffff;
  }
  /* Other dark mode styles */

p {
    color: #fff !important;
}

h3 {
    color: #fff !important;
}

h4 {
  color: #fff !important;
}

a {
  background: linear-gradient(180deg, #FF3C58 2.22%, #FF3C9A 82.17%);
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
  font-weight: bold;
}

label {
  color: #fff !important;
}

input {
  color: #fff;
}

.dark-span {
  color: #111;
}


.navbar {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    width: 100%;
    height: 69px;
    background-color: #202020;
    border-bottom: 0px solid #202020;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 110;
    box-shadow: 0px 1px 28px -3px rgba(0, 0, 0, 0.1);
    /* border-radius: 35px; */
}

.navbar-logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #121212;
  padding: 10px;
}

.profile-image {
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-right: 20px;
}



/* Navbar dropdown menu in dark mode */
.navbar .dropdown-menu {
    background-color: #444444 !important;
    color: #ffffff !important;
    position: absolute;
    right: 30;
    top: 100%;
    width: 100px;

    /* border: 1px solid #666666 !important; */
  }
  
  /* Navbar dropdown menu links in dark mode */
  .navbar .dropdown-menu a {
    color: #ffffff !important;
  }
  
  /* Navbar dropdown menu button in dark mode */
  .navbar .dropdown-menu button {
    background-color: #666666 !important;
    color: #ffffff !important;
    /* border: 1px solid #666666 !important; */
  }
  
  /* Navbar dropdown menu button hover state in dark mode */
  .navbar .dropdown-menu button:hover {
    background-color: #777777 !important;
    color: #ffffff !important;
    /* border: 1px solid #666666 !important; */
  }
  
  .login-title {
    position: relative;
    display: block;
    margin: auto;
    text-align: center;
    top: 30px;
    color: #fff;
}

.card-container-login {
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 1px 28px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
    padding-top: 28px;
    border: none;
    top: -160px;
    background-color: #202020;
    color: #fff;
}

.card-container-sign-up {
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 1px 28px -3px rgba(0, 0, 0, 0.1);
  border-radius: 35px;
  padding: 30px;
  padding-top: 60px;
  border: none;
  top: -80px;
  background-color: #202020;
  color: #fff;
}

.card-public {
  text-align: center;
  width: 300px;
  margin: 0 auto;
  box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
  border-radius: 35px;
  padding: 30px;
  color: #fff;
  border: none;
  background-color: #202020;
}

.dark-mode-input {
    background-color: #444444;
    color: #ffffff;
    border: 1px solid #666666;
  }

.card-container {
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 1px 28px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
    padding-top: 28px;
    border: none;
    background-color: #202020;
    color: #fff;
}



.event-item {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    box-shadow: 0px 1px 28px -3px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 8px 25px;
    width: 360px;
    height: 55px;
    background-color: #202020;
    color: #fff;
}


.past-event {
    background-color: #454545; ;
}


.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(17, 17, 17, 0.8);
    z-index: 9999;
  }

  .ant-spin-dot-item {
    background-color: #FF3D5A !important;
  }


  .dayze-coin-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #3b3b3b;
    border-radius: 25px;
    padding: 6px 5px;
    height: 28px;
    width: auto;
    color: #fff;
  }


.social-icon {
  color: white;
}


  @media only screen and (max-width: 480px) {
    .navbar {
      width: 100%;
      height: 52px;
    }

    .profile-image {
      background-size: cover;
      border-radius: 50%;
      height: 34px;
      width: 34px;
      cursor: pointer;
      margin-right: 14px;
    }

    .navbar-logo img {
      height: 22px;
      margin-left: 5px;
  }

  .dayze-coin-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #3b3b3b;
    border-radius: 25px;
    padding: 5px 3px;
    height: 20px;
    width: auto;
    font-size: 13px;
  }

  .dayze-coin-image {
    width: 22px;
    margin-right: 4px;
  }


  }


