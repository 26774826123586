/* Light mode styles (default) */
body {
    background-color: #ffffff;
    color: #333333;
}

a {
    background: linear-gradient(180deg, #FF3C58 2.22%, #FF3C9A 82.17%);
    -webkit-background-clip: text;
    color: transparent;
    text-decoration: none;
    font-weight: bold;
}

.no-auto-zoom input[type="text"],
.no-auto-zoom input[type="password"],
.no-auto-zoom input[type="email"],
.no-auto-zoom input[type="number"],
.no-auto-zoom input[type="tel"],
.no-auto-zoom input[type="date"],
.no-auto-zoom input[type="time"],
.no-auto-zoom input[type="datetime"],
.no-auto-zoom input[type="datetime-local"],
.no-auto-zoom textarea {
    font-size: 16px;
    max-height: 50px;
    -webkit-text-size-adjust: none;
}

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 35px;
}

.day-counter {
    position: relative;
    top: 60px;
    z-index: 100;
    text-align: center;
    font-size: 14px;
}

.card-container {
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
    padding-top: 40px;
    border: none;
}

.card-container-login {
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
    padding-top: 40px;
    border: none;
    top: -160px;
}

.login-button {
    background: linear-gradient(88.91deg, #FF3D5A 2.27%, #FF3D99 101.07%);
}

.branded-button {
    background: linear-gradient(88.91deg, #FF3D5A 2.27%, #FF3D99 101.07%);
}

.ant-form-item-explain-error {
    margin-top: 14px;
}

.card-container-sign-up {
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
    padding-top: 60px;
    border: none;
    top: -80px;
}

.card-public {
    text-align: center;
    width: 300px;
    margin: 0 auto;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
}

.gradient-link {
    font-weight: bold;
    background: linear-gradient(180deg, #FF3C58 2.22%, #FF3C9A 82.17%);
    -webkit-background-clip: text;
    color: transparent;
    text-decoration: none;
}

.no-day-age {
    font-weight: bold;
    background: linear-gradient(180deg, #FF3C58 2.22%, #FF3C9A 82.17%);
    -webkit-background-clip: text;
    color: transparent;
    text-decoration: none;
    position: relative;
    top: 60px;
    z-index: 100;
}

.day-counter {
    position: relative;
    z-index: 100;
    text-align: center;
    font-size: 14px;
}

.auth-container .ant-form-item:first-child .ant-form-item-control-input {
    margin-bottom: -12px;
}

.share-button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10;
    background: linear-gradient(180deg, #FF3C58 2.22%, #FF3C9A 82.17%);
    box-shadow: 0 6px 12px #0a0b0d14;
}

.BrandLogo {
    position: relative;
    display: block;
    margin: auto;
    width: 240px;
    top: 36px;
}

.login-title {
    position: relative;
    display: block;
    margin: auto;
    text-align: center;
    top: 30px;
    color: #292b2c;
}

.BrandLogoFooter {
    width: 80px;
    position: fixed;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.fixedButtonViewProfile {
    position: fixed;
    bottom: 30px;
    left: 50%;
    height: 50px;
    border: none;
    transform: translateX(-50%);
    border-radius: 44px;
    box-shadow: 0 6px 12px #0a0b0d14;
    padding: 14px 24px;
    transition: width .2s, padding .2s;
}

.fixedButtonBackToHome {
    position: fixed;
    bottom: 90px;
    left: 50%;
    height: 50px;
    border: none;
    transform: translateX(-50%);
    border-radius: 44px;
    box-shadow: 0 6px 12px #0a0b0d14;
    padding: 14px 24px;
    transition: width .2s, padding .2s;
}

.events-card-container {
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding: 30px;
    border: none;
}

.events-card-container ul {
    list-style-type: none;
    padding-left: 0;
}

.events-card-container li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.event-header {
    padding-left: 22px;
    padding-top: 20px;
    margin-bottom: -1px;
}

.event-list {
    list-style: none;
    padding: 0;
    width: 100%;
}

.event-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.event-item {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 8px 25px;
    width: 360px;
    height: 55px;
}

.event-title {
    margin-right: 16px;
    width: 130px;
}

.event-time {
    width: 150px;
    text-align: right;
    margin-right: 16px;
}

.past-event {
    background-color: #EEFFCA;
}

.people-item {
    margin-top: 10px;
    width: 360px;
    max-width: 500px;
    box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
    background-color: #202020;
    border-radius: 20px;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
}

.people-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}

.people-name {
    text-align: left;
    font-size: 15px;
}

.people-item .people-name {
    text-align: left;
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal img {
    width: 90vw;
    height: 90vw;
    object-fit: cover;
    border-radius: 50%;
}

.following-followers-count {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0px;
}

.following-title {
    text-align: center;
    margin: 0;
}

.following-container {
    /* margin-right: 30px; */
}

.followers-container {
    margin-right: 30px;
}



.left-column,
.right-column {
    display: flex;
    flex-direction: column;
}

.left-column {
    align-items: center;
}

.right-column {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

:root .event-modal .ant-modal-content {
    border-radius: 35px;
    padding: 25px 32px;
}

.event-modal .ant-form-item-label label {
    color: black !important;
}

.ant-modal-title {
    margin-left: 10px;
}

.view-as-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
}

.social-icon {
    color: black;
    font-size: 22px;
}

.form-group {
    position: relative;
}

.form-label {
    position: absolute;
    left: 12px;
    top: 14px;
    color: #999;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    opacity: 0.4;
    background-color: transparent;
    font-size: 14px;
}

.form-input {
    width: 100%;
    border: none;
    padding: 28px 16px 10px;
    border-radius: 12px;
    background-color: #F9F9F9;
}

.form-input:focus+.form-label,
.form-input:not(:placeholder-shown)+.form-label {
    top: 5px;
    left: 15px;
    font-size: 11px;
    color: #737679;
    opacity: 1;
}

.handleName {
    font-size: 20px !important;
    line-height: 1.5;
    font-weight: 700;
    margin: 0px;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 5px yellow, 0 0 10px yellow, 0 0 15px yellow, 0 0 20px yellow;
    }

    50% {
        box-shadow: 0 0 10px yellow, 0 0 20px yellow, 0 0 30px yellow, 0 0 40px yellow;
    }

    100% {
        box-shadow: 0 0 5px yellow, 0 0 10px yellow, 0 0 15px yellow, 0 0 20px yellow;
    }
}

.rotating-ball {
    animation: glowing 2s ease-in-out infinite alternate;
}