.form-item-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    /* Adjust as needed */
}

.form-item-inline label {
    flex-basis: 50%;
    /* Adjust based on your layout */
    margin-right: 10px;
    /* Provides spacing between label and switch */
}


.ant-notification-notice-dark {
    background-color: #333 !important;
    /* Dark background */
    color: #fff !important;
    /* Light text color */
}

.ant-notification-notice-message {
    background-color: #333 !important;
    /* Dark background */
    color: #fff !important;
    /* Light text color */
}