.search-container {
    position: relative;
    margin: 10px;
    display: flex;
    align-items: center;
  }
  
  .search-input {
    padding: 14px;
    flex-grow: 1;
    border: none;
    background-color: #181818;
    color: white;
    border-radius: 50px;
    outline: none;
    font-size: 14px;
  }
  
  .clear-button {
    margin-left: -30px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
  