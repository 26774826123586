/* Reset CSS for body */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000000 !important;
    font-family: 'Inter', sans-serif;
}

.logo {
    width: 22px;
    margin: -5px;
    margin-left: -60px;;
}

.logo-text {
    padding: 12px;
    font-size: 16px;
}

.login-link, 
.login-link:visited,
.login-link:hover,
.login-link:active {
    color: #fff !important;
}

.signup-btn .ant-btn-primary:hover {
    background-image: linear-gradient(180deg, #FF3D5A 0%, #FF3D98 100%);
    box-shadow: 0px 0px 42px rgba(255, 0, 15, 0.87);
    color: white;
}

.signup-btn {
    padding-left: 30px;
    margin-right: -70px;
}

/* General Styles */
.content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    color: white;
    background-color: #000;
}

.title, .description {
    text-align: center;
    margin-bottom: 20px;
    max-width: 80%;
}

/* Navbar Styles */
.navbar-landing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000;
    padding: 0; 
    width: 100%;
    top: 0;
    /* z-index: 2;
    position: relative; */
}

.logo-item, .login-link, .signup-btn .ant-btn-primary {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 800;
}

.right-menu {
    display: flex;
    align-items: center;
}

.ant-menu-item {
    padding-inline: 0px !important;
}

/* Content Styles */
.title {
    font-size: 183px;
    line-height: 93.523%;
    letter-spacing: -0.361px;
    -webkit-text-stroke: 0.8px #fff;
    color: transparent;
}

.title-1 {
    font-size: 123px;
    line-height: 93.523%;
    letter-spacing: -0.361px;
    -webkit-text-stroke: 0.8px #fff;
    color: transparent;
    text-align: center;
}

.description {
    font-size: 14px;
    max-width: 832px;
    text-align: center;
}

/* Button Styles */
.signup-btn .ant-btn-primary {
    background-color: white; 
    color: black;
    border: none;
    border-radius: 30px;
    margin-right: 8px;
}

.cta-btn {
    background-image: linear-gradient(180deg, #FF3D5A 0%, #FF3D98 100%);
    box-shadow: 0px 0px 42px rgba(255, 0, 15, 0.87);
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 100px !important;
    margin-top: 34px;
    padding: 0px 24px !important;
    height: 46px !important;
    animation: pulsate 4.0s infinite;
}

@keyframes pulsate {
    0% {
        box-shadow: 0px 0px 22px rgba(255, 0, 15, 0.57);
    }
    50% {
        box-shadow: 0px 0px 42px rgba(255, 0, 15, 0.97);
    }
    100% {
        box-shadow: 0px 0px 22px rgba(255, 0, 15, 0.57);
    }
}

.starfield-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Set to 0 or another low value */
}

.section-one {
    justify-content: center;
    background-color: #000000;
    max-width: 800px; /* Or any other max width */
    width: 100%;
    margin: auto; /* Centers the content block */
}

.section-content {
    width: 100%;
    justify-content: center;
}

/* Overriding Ant Design Collapse for Dark Mode */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    /* background-color: #333; */
    color: white; /* Light text */
}

.ant-collapse-content > .ant-collapse-content-box {
    background-color: #111;
    color: white; /* Light text for content */
}

.ant-collapse > .ant-collapse-item {
    /* border-bottom: 1px solid #555; */
}


.day-age-calculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.day-age-calculator input, .day-age-calculator button {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
}

/* Add more styles as per your design preference */



/* Media Queries */

@media (min-width: 1024px) and (max-width: 1366px) {
    .title {
        font-size: 100px;
        -webkit-text-stroke: 1px #fff;
    }

    .description {
        font-size: 14px;
    }

    .cta-btn {
        font-size: 20px;
        height: 50px;
        width: auto;
        padding: 0 40px;
    }
}

/* Regular iPad in landscape mode */
@media (min-width: 768px) and (max-width: 1023px) {
    .title {
        font-size: 80px;
        -webkit-text-stroke: 0.7px #fff;
    }

    .description {
        font-size: 14px;
        width: 100%;
    }

    .cta-btn {
        font-size: 18px;
        height: 45px;
        width: auto;
        padding: 0 35px;
    }
}


@media (max-width: 992px) {
    .title {
        font-size: 100px;
    }

    .navbar-landing {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 55px;
        -webkit-text-stroke: 0.6px #fff;
    }

    .description {
        font-size: 13px;
        width: 340px;
    }

    .signup-btn .ant-btn-primary, .logo-item {
        margin: 0 10px; /* Adjusted margin */
    }

    .signup-btn {
        padding-left: 4px;
    }
}

.beta-tag {
    font-size: 0.55em;
    vertical-align: super;
    margin-left: 8px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    pointer-events: none;
    user-select: none;
}

