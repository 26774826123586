.navbar {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  right: 0px;
  width: 90%;
  height: 56px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 20px; */
  z-index: 110;
  box-shadow: 0px 1px 40px -3px rgba(0, 0, 0, 0.1);
  /* border-radius: 35px; */
}

.navbar-logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #121212;
  padding: 10px;
}

.navbar-logo img {
  height: 26px;
  margin-left: 10px;
  margin-right: 12px;
}

.navbar-search {
  display: flex;
  align-items: center;
  margin-right: auto;  /* To push it towards the left, near the logo */
}

.search-icon {
  cursor: pointer;
  font-size: 20px;
  margin-left: 15px;  /* Adjust as needed */
}

.search-input {
  background-color: #3b3b3b;
  padding: 6px 18px;
  height: 12px;
  border: none;
  border-radius: 100px;
  outline: none;
  font-size: .9375rem;
  color:#F9F9F9;
}

.navbar-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.navbar-menu a {
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}

.navbar-menu a:hover {
  color: #1890ff;
}

.profile-image {
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-right: 20px;
}

.menu-link {
  font-weight: normal !important;
}


/* navbar.css */
.dropdown-menu {
  position: absolute;
  right: 30;
  top: 100%;
  background-color: white;
  /* border-radius: 4px; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100px;
}

.dropdown-menu a,
.dropdown-menu button {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.dayze-coin-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;
  border-radius: 25px;
  padding: 6px 5px;
  height: 28px;
  width: auto;
  /* color: #fff; */
}

.dayze-coin-image {
  width: 28px;
  margin-right: 5px;
}



.dropdown-content {
  position: fixed;
  top: 69px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(28px);
}

.centered-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.box {
  width: 158px;
  height: 173px;
  background: #1b1b1b;
  /* opacity: 0.61; */
  border-radius: 22px;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 8px;
}

.pill-button {
  width: 142px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 2px rgba(255, 255, 255, 0.51);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 8px;
}



.dark-mode {
  background-color: #333 !important;
}

.dark-mode .ant-dropdown-menu-item {
  color: #fff !important;
}

.dark-mode .ant-dropdown-menu-item:hover {
  background-color: #555 !important;
}






@media only screen and (max-width: 480px) {
  .navbar {
    width: 100%;
    height: 52px;
  }

  .profile-image {
    background-size: cover;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    cursor: pointer;
    margin-right: 14px;
  }

  .navbar-logo img {
    height: 22px;
    margin-left: 5px;
  }

  .dayze-coin-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #f4f4f4;
    border-radius: 25px;
    padding: 5px 3px;
    height: 20px;
    width: auto;
    font-size: 13px;
  }

  .dayze-coin-image {
    width: 22px;
    margin-right: 4px;
  }


  .dropdown-content {
    top: 52px;
  }

}