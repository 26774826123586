/* PolarisChat.css */

.no-auto-zoom input[type="text"],
.no-auto-zoom input[type="password"],
.no-auto-zoom input[type="email"],
.no-auto-zoom input[type="number"],
.no-auto-zoom input[type="tel"],
.no-auto-zoom input[type="date"],
.no-auto-zoom input[type="time"],
.no-auto-zoom input[type="datetime"],
.no-auto-zoom input[type="datetime-local"],
.no-auto-zoom textarea {
  font-size: 16px;
  max-height: 50px;
  -webkit-text-size-adjust: none;
}

.polaris-chat-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  background: linear-gradient(180deg, #FF3D5A 0%, #FF3D98 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 0px 42px rgba(255, 0, 15, 0.35);
}

.polaris-chat-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.polaris-chat-button {
  width: 48px;
  height: 48px;
  background: linear-gradient(180deg, #FF3D5A 0%, #FF3D98 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 42px rgba(255, 0, 15, 0.35);
}

.polaris-chat {
  position: fixed;
  bottom: 70px;
  right: 10px;
  max-width: 60%;
  overflow-y: auto;
  max-height: 90vh;
  z-index: 1001;
  display: none;
  transition: background-color 0.2s ease-in-out;
}

.polaris-chat.active {
  display: block;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  padding: 1rem;
  margin-bottom: 20px;
}

.message-timestamp {
  font-size: 0.7em;
  color: #999;
  margin-left: 5px;
  vertical-align: bottom;
}

.polaris-chat input {
  color: #111;
  width: 60%;
  height: 34px;
  border-radius: 100px;
  padding-left: 15px;
  padding-right: 46px;
  outline: none;
  border: none;
  right: -20px;
  bottom: 9px;
  position: relative;
}

.chat-input-button {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  border: none;
  padding: none;
  margin: none;
  right: 16px;
  bottom: 10px;
  position:relative;
  color: white;
  background: linear-gradient(180deg, #FF3D5A 0%, #FF3D98 100%); /* Updated linear gradient background */
}

.chat-input-button.active {
  background-color: red;
  transition: background-color 0.2s ease-in-out;
}

.polaris-chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(180deg, #FF3C58 2.22%, #FF3C9A 82.17%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color 0.3s;
}

.polaris-chat-icon:hover {
  background: linear-gradient(180deg, #FF3D5A 0%, #FF3D98 100%);
}

.polaris-chat-container.active {
  display: block;
}

.polaris-chat-container {
  position: fixed;
  bottom: 84px;
  right: 20px;
  width: 100%;
  max-width: 400px;
  height: 705px;
  max-height: calc(100vh - 104px);
  display: none;
  overflow-y: auto;
  flex-direction: column;
  border-radius: 16px;
  background-color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
  opacity: 1;
}

.polaris-chat-message {
  max-width: 60%;
  width: auto;
  padding: 8px 15px;
  border-radius: 15px;
  word-wrap: break-word;
  display: inline-block;
  clear: both;
  position: relative;
  padding-bottom: 15px;
}

.message-timestamp {
  position: absolute;
  bottom: 2px;
  right: 5px;
}

.polaris-chat-message.sender {
  background-color: #ff3c60;
  color: #fff;
  float: right;
  max-width: 180px;
  border-bottom-right-radius: 0;
  animation: fadeIn 0.5s ease-in-out;
  text-align: right;
}

.polaris-chat-message.receiver {
  background-color: #f1f0f0;
  color: #222;
  float: left;
  max-width: 180px;
  border-bottom-left-radius: 0;
  animation: fadeIn 0.5s ease-in-out;
  text-align: left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.polaris-chat-container.visible {
  display: flex;
}


.polaris-chat-header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.polaris-chat-header-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.polaris-chat-message {
  margin-bottom: 10px;
  font-size: 15.5px;
}

.polaris-chat-input-container {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.polaris-chat-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.loading {
  animation: loading 1s steps(3, end) infinite;
}

@keyframes loading {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.load-more-button {
  display: block;
  margin: 10px auto;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.load-more-button:hover {
  background-color: #e0e0e0;
}