.form-input {
    color: #111;
}

.form-label {
    color: #333 !important;
    font-size: 14px !important;
    position: relative;
    top: -36px !important;
}

.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
    position: relative;
    color: #313131 !important;
    top: -53px !important;
    font-size: 11px !important;
}


@media (max-width: 767px) {
    .form-input {
        /* width: 100% !important; */
        width: 292px !important;
        height: 50px !important;
    }
}