/* loading-screen.css */
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: #000;
    z-index: 9999;
  }

  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-spin-dot-item {
    background-color: #FF3D5A !important; 
  }

.loader-bar {
  position: absolute;
  height: 2px;
  width: 34px;
  background-color: #444;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 64px;
}

.loader-bar-progress {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
}