
.following-container, .followers-container {
    cursor: pointer;
}

.centered-modal-title .ant-modal-title {
    text-align: center;
}

.meta-title {
    color: black;
}

.custom-list-item-meta .ant-list-item-meta-title {
    line-height: 0.4 !important; /* Using !important to override default styles */
    margin-top: 13.5px !important;
}

.custom-list .ant-list-item {
    border-bottom: none; /* This removes the dividing line */
    margin-bottom: -10px;
}


